import axios from '@axios'

export default {
  namespaced: true,
  state: {
    modalityOptions: [
      { title: 'Presencial', value: 'face-to-face' },
      { title: 'Semipresencial', value: 'blended' },
      { title: 'Online', value: 'online' },
      { title: 'Streaming', value: 'streaming' },
    ],
    statuses: [
      { title: 'Alta', value: 'alta' },
      { title: 'Enviado a sucursal', value: 'inscripcion-enviada' },
      { title: 'Inscripto', value: 'inscripto' },
      { title: 'Cierre enviado', value: 'cierre-enviado' },
      { title: 'Finalizado', value: 'finalizado' },
    ],
    institutes: [
      { title: 'Capacitación Empresarial', value: 'empresarial' },
      { title: 'Negocios y Administración', value: 'negocios' },
      { title: 'Economía y Finanzas', value: 'economia' },
      { title: 'Diseño y Comunicación', value: 'diseno' },
      { title: 'Sistemas y Tecnologías', value: 'sistemas' },
      { title: 'Prado', value: 'prado' },
      { title: 'Online', value: 'online' },
    ],
  },
  getters: {},
  mutations: {},
  actions: {
    fetchRegulars(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/regular/getRegulars`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRegular(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/regular/getRegular/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRegular(ctx, regularData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/regular/update/${regularData.regular._id}`, regularData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRegular(ctx, regularData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/regular/save`, regularData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRegular(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/regular/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addObservation(ctx, regularData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/regular/addObservation/${regularData.regular._id}`, regularData.observation)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteObservation(ctx, regularData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/regular/deleteObservation/${regularData.regular._id}`, regularData.observation)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendEnrollment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/regular/sendEnrollment/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFiles(ctx, files) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/regular/uploadFiles`, files)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
