<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Info General</v-tab>
      <v-tab>Observaciones</v-tab>
    </v-tabs>

    <v-card flat>
      <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="onSubmit">
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row class="mt-1">
                <v-col cols="9" md="3">
                  <users-selection
                    v-model="regular.student"
                    :usersList="studentsList"
                    label="Estudiante"
                    :rules="[checkInfo]"
                    :loading="loadings.usersSelection"
                    outlined
                    dense
                  ></users-selection>
                </v-col>
                <v-col cols="1"
                  ><v-btn small color="primary" @click="fetchUsers(regular.student)">
                    <v-icon size="20">
                      {{ icons.mdiRefresh }}
                    </v-icon>
                  </v-btn></v-col
                >

                <v-col cols="12" md="4">
                  <companies-selection v-model="regular.company" :rules="[validators.required]"></companies-selection>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="regular.courseName"
                    :rules="[validators.required]"
                    outlined
                    dense
                    label="Nombre del Curso"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="regular.reference"
                    :rules="[validators.required]"
                    outlined
                    dense
                    label="Referencia"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-select
                    v-model="regular.modality"
                    :rules="[validators.requiredSelect]"
                    :items="$store.state['app-regular'].modalityOptions"
                    label="Modalidad del Curso"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" md="4">
                  <v-select
                    v-model="regular.institute"
                    :rules="[validators.requiredSelect]"
                    :items="$store.state['app-regular'].institutes"
                    label="Instituto"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="regular.shift"
                    :rules="[validators.required]"
                    outlined
                    dense
                    label="Turno"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <simple-date-picker
                    v-model="regular.start"
                    :rules="[validators.required]"
                    label="Fecha de Inicio"
                    outlined
                    dense
                  ></simple-date-picker>
                </v-col>

                <v-col cols="6" md="4">
                  <simple-date-picker
                    v-model="regular.end"
                    :rules="[validators.required]"
                    label="Fecha de Fin"
                    outlined
                    dense
                  ></simple-date-picker>
                </v-col>

                <v-col cols="3" md="2">
                  <v-text-field
                    v-model.number="regular.workLoad"
                    :rules="[validators.required]"
                    label="Carga Horaria"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="3" md="2">
                  <v-text-field
                    v-model.number="regular.journeys"
                    label="Jornadas"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col md="2" cols="6">
                  <v-text-field
                    v-model.number="regular.price"
                    :rules="[validators.required]"
                    label="Precio"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col md="2" cols="6">
                  <v-text-field
                    v-model.number="regular.studentPay"
                    label="Estudiante Paga"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col md="2" cols="6">
                  <v-text-field
                    v-model.number="regular.companyPay"
                    label="Empresa Paga"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="regular.observation"
                    outlined
                    dense
                    maxlength="50"
                    counter
                    label="Observación"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <users-selection
                    v-model="regular.admin"
                    :usersList="adminsList"
                    label="Administrador"
                    :rules="[validators.requiredSelect]"
                    outlined
                    dense
                  ></users-selection>
                </v-col>

                <v-col cols="6" md="4">
                  <v-select
                    v-model="regular.status"
                    :rules="[validators.requiredSelect]"
                    :items="$store.state['app-regular'].statuses"
                    label="Estado"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <observations v-model="regular.observations" :regular="regular"></observations>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" type="submit" :loading="loadings.save" :disabled="loadings.save"> Guardar </v-btn>
          <v-btn color="secondary" outlined :to="{ name: 'apps-regular-list' }"> Volver </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { ref, watch, inject, watchEffect, onUnmounted } from 'vue'
import { required, filesValidator, requiredSelect } from '@core/utils/validation'
import { mdiEyeOutline, mdiEyeOffOutline, mdiRefresh } from '@mdi/js'
import snackbarPlugin from '@/plugins/snackbar'

import store from '@/store'

import UsersSelection from '@/views/components/UsersSelection.vue'
import SimpleDatePicker from '@/views/components/SimpleDatePicker.vue'

import Observations from './Observations/Observations.vue'

import CompaniesSelection from '@/views/components/CompaniesSelection.vue'

export default {
  components: {
    UsersSelection,
    SimpleDatePicker,
    Observations,
    CompaniesSelection,
  },
  props: {
    regularData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const regular = ref({})
    const regularBckp = ref({})
    const studentsList = ref([])
    const adminsList = ref([])

    const tab = ref('')
    const date = inject('date')

    const valid = ref(false)
    const form = ref(null)

    const loadings = ref({
      save: false,
      usersSelection: false,
    })

    const files = ref([])

    const reset = () => {
      form.value.reset()
    }

    const resetValidation = () => {
      form.value.resetValidation()
    }

    const validate = () => {
      form.value.validate()
    }

    const checkInfo = value => {
      const errors = []
      if (!value || !value.length) return 'Debe seleccionar una opción'
      
      if(!studentsList.value.length) return true

      const userInfo = studentsList.value.find(student => student._id == value)

      if (!userInfo.address) errors.push('Dirección')
      if (!userInfo.city) errors.push('Ciudad')
      if (!userInfo.phone) errors.push('Teléfono')
      if (!userInfo.email) errors.push('Email')
      if (!userInfo.birthday) errors.push('Fecha de nacimiento')

      return errors.length == 0 || 'Usuario sin: ' + errors.join(', ')
    }

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        loadings.value.save = true

        const regularToSave = { ...regular.value }

        if (regularToSave.start) regularToSave.start = date(regularToSave.start).toISOString()
        if (regularToSave.end) regularToSave.end = date(regularToSave.end).toISOString()
        
        if(!regularToSave.observations) regularToSave.observations = []

        if (regularToSave._id) {
          store
            .dispatch('app-regular/updateRegular', { regular: regularToSave, regularBckp: regularBckp.value })
            .then(r => {
              loadings.value.save = false
              emit('already-save', true)
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
            })
            .catch(e => {
              loadings.value.save = false
              snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
            })
        } else {
          store
            .dispatch('app-regular/addRegular', regularToSave)
            .then(r => {
              loadings.value.save = false
              emit('already-save', true)
              emit('reloadRegular', r.data.regular._id)
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
            })
            .catch(e => {
              loadings.value.save = false
              snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
            })
        }
      } else {
        validate()
      }
    }

    const fetchUsers = user => {
      if (user) regular.value.student = null
      loadings.value.usersSelection = true
      store
        .dispatch('user/fetchUsers', {
          role: 'student,admin',
        })
        .then(response => {
          const { filteredData } = response.data

          studentsList.value = filteredData.filter(user => user.role == 'student')
          adminsList.value = filteredData.filter(user => user.role == 'admin')
          if (user) regular.value.student = user
          loadings.value.usersSelection = false
          resetValidation()
        })
        .catch(error => {
          console.log(error)
          loadings.value.usersSelection = false
        })
    }

    fetchUsers()

    watch(
      () => props.regularData,
      value => {
        if (value.start) value.start = date(value.start).format('YYYY-MM-DD')
        if (value.end) value.end = date(value.end).format('YYYY-MM-DD')
        regular.value = value
        regularBckp.value = { ...value }
      },
    )

    watchEffect(() => {
      if (store.state['app-regular'].statuses.length > 0 && !regular.value.status) {
        regular.value.status = 'alta'
      }
    })

    return {
      resetValidation,
      regular,
      form,
      tab,
      onSubmit,
      valid,
      validate,
      validators: { required, filesValidator, requiredSelect },
      loadings,
      files,
      checkInfo,
      studentsList,
      adminsList,
      fetchUsers,

      // icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiRefresh,
      },
    }
  },
}
</script>
