<template>
  <div id="regular-view">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Regular {{ regularData.name }} </v-card-title>
          <regular-panel :regular-data="regularData" @reloadRegular="id => fetchRegulars(id)" @already-save="onAlreadySave"></regular-panel>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from 'vue'
import router from '@/router'
import store from '@/store'

import RegularPanel from './regular-panel/RegularPanel.vue'
import regularStoreModule from '../regularStoreModule'

let alreadySave = false

export default {
  components: {
    RegularPanel,
  },
  setup() {
    const appRegular = 'app-regular'

    // Register module
    if (!store.hasModule(appRegular)) store.registerModule(appRegular, regularStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appRegular)) store.unregisterModule(appRegular)
    })

    const regularData = ref({})

    const onAlreadySave = e => {
      alreadySave = e
    }

    const fetchRegulars = (id) => {
      store
        .dispatch('app-regular/fetchRegular', { id })
        .then(response => {
          regularData.value = response.data.regular
        })
        .catch(error => {
          if (error.response.status === 404) {
            regularData.value = {}
          }
        })
    }

    if (router.currentRoute.params.id) fetchRegulars(router.currentRoute.params.id)

    return {
      fetchRegulars,
      regularData,
      onAlreadySave,
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (!alreadySave) {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: 'Esta seguro que desea cerrar',
        message: 'Puede perder los cambios',
      })
      if (didConfirm) {
        next()
      } else {
        next(false)
      }
    } else next()
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/group.scss';
</style>
